// 
// choices.scss
// Vendor style override and new style added

.choices{
  margin-bottom: 0;
  .choices__inner{
    background-color: $input-bg;
    color: $input-color;
    border: $input-border-width solid $input-border-color;
    line-height: $input-line-height;
    @include font-size($input-font-size);
    @include border-radius($input-border-radius);
    min-height: auto;
    padding: $input-padding-y $input-padding-x !important;
  }
 
  .choices__list--multiple .choices__item{
    background-color: var(--#{$prefix}gray-300);
    border: 1px solid var(--#{$prefix}gray-300);
    border-radius: $border-radius;
    color: var(--#{$prefix}gray-600);
    padding: 1px 10px !important;
  }

  .choices__list--dropdown{
    border: none;
    z-index: 1010;
    background-color: var(--bs-mode);
    border: $input-border-width solid $input-focus-border-color !important;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    visibility: visible !important;
    .choices__item--selectable.is-highlighted{
      background-color: rgba(var(--bs-primary-rgb), 0.2);
      color: var(--#{$prefix}primary);
    }
  }
  
  .choices__list--dropdown,
  .choices__list[aria-expanded]{
    margin-top: 0px;
  }
  &.is-open .choices__list--dropdown, 
  &.is-open .choices__list[aria-expanded]{
    margin-top: -1px;
  }

  .choices__list--dropdown .choices__item{
    color: var(--#{$prefix}gray-600) !important;
    font-size: 15px;
  }
  .choices__list--dropdown .choices__item.choices__placeholder{
    color: $input-placeholder-color !important;
    opacity: 1 !important;
    font-size: 14px;
  }
}

.choices.is-flipped .choices__list--dropdown{
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
}
.choices.is-flipped[data-type*=select-one] .choices__inner, .choices.is-flipped[data-type*=select-multiple] .choices__inner{
  border-radius: 0 0 $border-radius $border-radius !important
}

.choices.is-focused .choices__inner{
  border-color: $input-focus-border-color !important;
}
.choices.is-focused[data-type*=select-one] .choices__inner, .choices.is-focused[data-type*=select-multiple] .choices__inner{
  border-color: $input-focus-border-color !important;
}
.choices__input{
  background-color: transparent;
  color: $input-focus-color;
}
.choices[data-type*=select-one] .choices__input{
  background: var(--bs-mode);
  color:  var(--#{$prefix}body-color);
  border-color: $input-border-color !important;
}

.choices__list--single {
  padding: 0;
}

.choices[data-type*=select-multiple] .choices__button, .choices[data-type*=text] .choices__button{
  border-left: 1px solid var(--#{$prefix}gray-500);
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
}

.choices[data-type*=select-one]:after {
  content: "";
  height: 12px;
  width: 12px;
  border: none;
  border-width: 5px;
  position: absolute;
  right: 11.5px;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0;
  background-image: escape-svg($form-select-indicator);
  background-repeat: no-repeat;
  background-position: center center;
}
.choices[data-type*=select-one].is-open:after {
  margin-top: 0;
}

// Content none
.choices__list--dropdown .choices__item--selectable:after{
  content: none;
}
.choices__list--dropdown .choices__item--selectable{
  padding-right: 0;
}
.choices__list--dropdown .choices__item{
  padding: 4px 10px;
}
.choices[data-type*=select-one] .choices__input {
  padding: 10px;
}
.choices .choices__input {
  margin-bottom: 0;
  padding: 0;
}

// Style for collapse body
.collapsing .choices__list--dropdown{
  display: none;
}
.collapse.show .choices__list--dropdown{
  display: block;
}

// Style for border radius
.choices[aria-expanded=true] .choices__inner{
  border-radius: $border-radius $border-radius 0 0 !important;
}
.choices__list--dropdown .choices__list {
  position: relative;
  max-height: 200px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
}

// RTL
html[dir="rtl"] .choices, 
html[dir="rtl"] select option,
html[dir="rtl"] .choices__input {
  direction: rtl;
  unicode-bidi: bidi-override;
  text-align: left;
}
[dir=rtl] .choices__list--dropdown .choices__item--selectable {
  padding-left: 10px; 
  padding-right: 0;
}

// Choices radius
.choice-radius-0 {
  .choices .choices__inner{
    border-radius: 0 !important;
  }
} 

.choice-radius-end {
  .choices .choices__inner{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right:0;
  }
  .choices[aria-expanded=true] .choices__inner{
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}  

.choice-radius-start {
  .choices .choices__inner{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
  }
  .choices[aria-expanded=true] .choices__inner{
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

// To remove itemSelectText on hover
.choices[data-type*=select-one] .choices__list > .choices__list > .choices__item--selectable{
	padding-right: 0px;
}
.choices[data-type*=select-one] .choices__list > .choices__list > .choices__item--selectable::after{
	display: none;
}

// Font size for form
.form-fs-lg {
  .choices {
    .choices__inner {
      font-weight: $font-weight-normal;
      font-size: $form-fs-lg !important;
    }
  }
}
.form-fs-md {
  .choices {
    .choices__inner {
      font-weight: $font-weight-normal;
      font-size: $form-fs-md;
    }
  }
}
.form-fs-sm {
  .choices {
    .choices__inner {
      font-weight: $font-weight-normal;
      font-size: $form-fs-sm;
    }
  }
}

// Form border
.form-control-border{
  .choices {
    margin-top: -1.2rem;
    .choices__inner {
      color: $headings-color;
      padding-top: 1rem !important;
      padding-bottom: 0.8rem !important;
    }
    .choices__list--dropdown{
      text-align: left;
    }
    &[data-type*=select-one]:after {
      display: none;
    }
    .choices__placeholder {
      opacity: .5;
    }
  }
}

// Form border bottom
.form-border-bottom{
  border-bottom: $input-border-width $border-style $input-border-color;

  .choices .choices__inner {
    border: none;
  }
}

// Background light
.form-control-bg-light {
  .choices .choices__inner{
    color: $headings-color;
    border: $border-width $border-style var(--#{$prefix}light);
    background-color: var(--#{$prefix}light) !important;
  }
}

// Choice form size lg
.form-size-lg {
  .choices.is-open[data-type*=select-one] .choices__inner, .choices.is-open[data-type*=select-multiple] .choices__inner{
    border-color: $input-focus-border-color !important;
    border-radius: $input-border-radius-lg $input-border-radius-lg 0 0 !important;
  }
  .choices .choices__inner{
    @include font-size($input-font-size-lg);
    @include border-radius($input-border-radius-lg);
    min-height: $input-height-lg !important;
    padding: $input-padding-y-lg $input-padding-x-lg !important;
    line-height: 1.5 !important;
  }
  .choices.is-flipped[data-type*=select-one] .choices__inner, .choices.is-flipped[data-type*=select-multiple] .choices__inner{
    border-radius: 0 0 $border-radius-lg $border-radius-lg !important
  }
}

// Input with icon
.form-icon-input{
  .choices .choices__inner{
    color: $headings-color;
    background-color: var(--#{$prefix}light);
    padding-left: 3.2rem !important;
  }
  .choices[data-type*=select-one]:after{
    display: none;
  }
}

// Form border transparent
.form-border-transparent{
  .choices .choices__inner{
    color: $headings-color;
    border: $border-width $border-style transparent;
  }
}

// Form control transparent
.form-control-transparent{
  .choices .choices__inner{
    background: transparent;
  }
}

// Form mix icon
.form-mix-icon-input{
  position: relative;
  .choices .choices__inner{
    padding-left: 2.7rem !important;
    padding-right: 2.5rem !important;
  }
  .choices[data-type*=select-one]:after{
    display: none;
  }
}
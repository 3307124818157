// 
// navbar.scss
// Extended from Bootstrap
// 

// 
// Bootstrap Overrides and Additional style for theme
// 


// `.navbar-expand-*` responsive classes for configuring
// where your navbar collapses.
.navbar-expand {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);
    
    // Styling for navbar-expand
    &#{$infix} {

      // Navbar styling for all screen sizes
			// Custom dropdown toggle icon
      .dropdown-toggle:after {
        content: "";
        font-family: "Font Awesome 6 Free";
        margin-left: 0.35rem;
        font-weight: 900;
        font-size: 0.8rem;
        vertical-align: middle;
        border: none;
      }
      .dropdown-submenu.dropend .dropdown-toggle:after {
        content: "";
        font-family: "Font Awesome 6 Free";
        right: 1rem;
        font-size: 1rem;
      }
      .dropdown-submenu.dropstart .dropdown-toggle:before{
        content: "";
        margin-right: 0;
        font-family: "Font Awesome 6 Free";
        right: 1rem;
				position: absolute;
      }

      // Navbar styling for navbar expand
      @include media-breakpoint-up($next) {
        
        .navbar-top{
          z-index: 1100 !important;
        }
        .navbar-brand .navbar-brand-item {
          height: $navbar-brand-height;
          display: block;
          width: auto;
         }

        //Dropdown menu open on hover
        .navbar-nav .dropdown>.dropdown-menu {
          top: 120%;
          visibility: hidden;
          opacity: 0;
          display: block;
          transition: all 0.1s ease-in-out;
        }
        .navbar-nav .dropdown:hover>.dropdown-menu {
          display: block;
          top: 100%;
          visibility: visible;
          opacity: 1;
          transition: all 0.3s ease-in-out;
        }

        //Dropdown submenu open on hover
        .navbar-nav .dropdown .dropdown-submenu>.dropdown-menu {
          visibility: hidden;
          opacity: 0;
          display: block;
          transition: all 0.1s ease-in-out;
        }
        .navbar-nav .dropdown .dropdown-submenu:hover>.dropdown-menu {
          display: block;
          visibility: visible;
          opacity: 1;
          transition: all 0.3s ease-in-out;
          box-shadow: $box-shadow;
          margin-left: 0.5rem;
        }
        .navbar-nav .dropdown .dropdown-submenu.dropstart:hover>.dropdown-menu {
          margin-right: 0.5rem;
        }
        .navbar-nav .dropdown>.dropdown-toggle:active {
          pointer-events: none;
        }

        // Navbar bottom active line
        .navbar-nav.nav-active-line .show > .nav-link, .navbar-nav.nav-active-line .nav-link.active {
          color: var(--bs-navbar-active-color);
          border-bottom: 2px $border-style var(--#{$prefix}primary);
        }

        // Navbar bottom line
        &.navbar-divider{
          border-bottom: $border-width $border-style $dropdown-divider-bg;
        }

        //Line height
        .navbar-nav .nav-link {
          // line-height: 40px;
          font-weight: $font-weight-normal;
        }

       	.dropdown-menu .dropdown-toggle:after {
          position: absolute;
        }

				// Dropdown menu shadow stacked
				.dropdown-menu-shadow-stacked {
					z-index: 5;
					.dropdown-submenu {
						z-index: unset !important;
					}
				
					.dropdown-menu {
						z-index: -1;
						> * {
							z-index: 1;
							position: relative;
						}
						&:before {
							content: "";
							position: absolute;
							top: 0;
							bottom: 0;
							right: 0;
							left: 0;
							background: $dropdown-bg;
							box-shadow: $box-shadow;
							border-radius: inherit;
						}
						li {
							position: relative;
						}
						box-shadow: none !important;
					}
				}
      }

      // Navbar styling for navbar collapse
      @include media-breakpoint-down($next) {
        
        .navbar-brand .navbar-brand-item {
          height: $navbar-mobile-brand-height;
        }
        .navbar-brand {
          padding: $navbar-mobile-brand-padding-y 0;
        }
        .navbar-nav-scroll {
          max-height: var(--#{$variable-prefix}scroll-height, 65vh);
        }

        // Responsive dropdown menu without navbar toggle. Collapse will open on .nav-item 
        .navbar-collapse {
          box-shadow: 0px 10px 30px rgba(83, 88, 93, .4);
          position: absolute;
          left: 0;
          right: 0;
          top: 100%;
          background: var(--#{$prefix}body-bg);
          border-top: 1px solid rgba(0, 0, 0, 0.1);

          .navbar-nav .nav-item {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding: 8px 20px;
          }
          .navbar-nav .nav-item .nav-link{
            padding: 0.25rem 0;
          }
         }

        &.navbar-dark{
          .nav-link{
            color: var(--#{$prefix}navbar-light-color);
            &:hover, &:focus {
              color: var(--bs-nav-link-hover-color);
          }
          }
          
        }

        // Dropdown submenu background
        .navbar-nav .nav-item > .dropdown-menu .dropdown-submenu .dropdown-menu {
          background: rgba(var(--bs-gray-700), 0.5) !important;
        }

        // Dropdown toggle icon
        .navbar-collapse .navbar-nav .dropdown-toggle::after {
          position: absolute;
          right: 25px;
        }

        //Toggler icon
        .navbar-toggler {
          // border-color: transparent !important; 
          border: none;
        }

        //Offcanvas body
        .offcanvas-body{
          padding: 1rem 0.875rem;
         }
        .offcanvas-body .navbar-nav .nav-item {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          padding: 8px 0;
        }

        // Dropdown toggle icon
        .offcanvas-body .navbar-nav .dropdown-toggle::after {
          position: absolute;
        }

        // Responsive Dropdown menu design
        .navbar-nav .dropdown-menu{
          border: 1px solid var(--bs-border-color);
          margin-top: 8px;
          box-shadow: none;
        }

        // Navbar category menu
        .navbar-nav.nav-primary-soft {
          .nav-item .nav-link {
            padding: 0.5rem 0.75rem;
          }
        }

        // Navbar offcanvas menu padding
        .navbar-nav.navbar-offcanvas-menu{
          .nav-item{
            padding: 0.5rem 0;
            .nav-link{
              padding: 0.25rem 0;
            }
          }
          .nav-item > .dropdown-menu {
            background: transparent;
            padding: 0;
            box-shadow: none;
            border-left: $border-style $border-width $border-color;
            border-radius: 0;
            margin-left: 8px;
          }
          .nav-item > .dropdown-menu .dropdown-item{
            &.active, &:hover{
              background: none !important;
            }
          }
        }

      }
    }
  }
}

//Header sticky css
@include media-breakpoint-up(xl){
  header.header-sticky-on {
    position: fixed;
    z-index: 1040;
    top: 0;
    left: 0;
    right: 0;
    background-color: var(--#{$prefix}body-bg);
    animation: fadeInDown .5s;
    width: 100%;
    border-bottom: $border-width solid $border-color;
  }
  header.header-sticky-on .navbar-top {
    display: none !important;
  }
  header.header-sticky-on .navbar-brand {
    padding: $navbar-brand-padding-y-sticky 0;
  }
  header.header-sticky-on .navbar-brand .navbar-brand-item {
    height: $navbar-brand-height-sticky;
  }
}


//Animation for fade in down
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

// Navbar transparent
.header-transparent {
	background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

// Navbar toggler icon animation
.navbar-toggler{
  padding: 10px 10px;
}
.navbar-toggler{
  padding: 10px 10px;
}
.navbar-toggler .navbar-toggler-animation {
  transition: all 0.3s ease-in-out;
  background-image: none;
  position: relative;
  height: 22px;
  width: 22px;
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
.navbar-toggler .navbar-toggler-animation span {
  transition: all 0.3s ease-in-out;
  transform: rotateZ(0deg);
  height: 2px;
  background: var(--#{$prefix}navbar-color);
  display: block;
  position: absolute;
  width: 100%;
  border-radius: 30px;
}
.navbar-toggler .navbar-toggler-animation span:nth-child(1) {
  top: 10%;
}
.navbar-toggler .navbar-toggler-animation span:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}
.navbar-toggler .navbar-toggler-animation span:nth-child(3) {
  bottom: 10%;
}
.navbar-toggler[data-bs-toggle=collapse][aria-expanded=true] .navbar-toggler-animation span:nth-child(1) {
  transform: rotateZ(45deg) translateY(-50%);
  top: 49%;
  transform-origin: 50% 0;
  width: 100%;
}
.navbar-toggler[data-bs-toggle=collapse][aria-expanded=true] .navbar-toggler-animation span:nth-child(2) {
  visibility: hidden;
  transition: all 0s ease-in-out;
}
.navbar-toggler[data-bs-toggle=collapse][aria-expanded=true] .navbar-toggler-animation span:nth-child(3) {
  transform: rotateZ(-45deg) translateY(-50%);
  bottom: 42%;
  transform-origin: 50% 0;
  width: 100%;
}


// Notification icon
.nav-notification{
  width: 2.6rem;
  height: 2.6rem;
  line-height: 2.6rem;
  text-align: center;
  font-size: 0.8rem;
}
//Nevbar mobile screen responsive
@include media-breakpoint-down(sm){
  .nav .nav-item {
    .nav-notification{
      width: 2rem;
      height: 2rem;
      line-height: 2rem;
      font-size: 0.8rem;
    }
    .avatar{
      height: 2rem;
      width: 2rem;
    }
  }
}

// Navbar dark
// .navbar-dark-static{
//   .nav-link{
//     color: $white !important;
//     &:hover,
//     &:focus {
//       color: var(--#{$prefix}nav-link-hover-color) !important;
//     }
//   }

//   .navbar-toggler .navbar-toggler-animation span {
//     background-color: $white;
//   }
//   .navbar-collapse.show{
//     .nav-link{
//       color: var(--#{$prefix}nav-link-color) !important;
//       &:hover,
//       &:focus {
//         color: var(--#{$prefix}nav-link-hover-color) !important;
//       }
//     }
//   }
// }

// To keep light navbar in dark mode and light mode use below class in header tag
html.dark-mode .navbar-light-static{
  .nav-link{
    color: $dark !important;
    &:hover,
    &:focus {
      color: var(--#{$prefix}nav-link-hover-color) !important;
    }
  }

  .navbar-toggler .navbar-toggler-animation span {
    background-color: $dark;
  }

  .navbar-collapse.show{
    .nav-link{
      color:  var(--#{$prefix}nav-link-color) !important;
      &:hover,
      &:focus {
        color: var(--#{$prefix}nav-link-hover-color) !important;
      }
    }
  }
}
html.dark-mode header.header-sticky-on{
  border: 0;
  .navbar-light-static{
    .nav-link{
      color: var(--#{$prefix}nav-link-color) !important;
      &:hover,
      &:focus {
        color: var(--#{$prefix}nav-link-hover-color) !important;
      }
   }
  }
}
// 
// offcanvas.scss
// Extended from Bootstrap
// 

//
// Bootstrap Overrides
//

.offcanvas-w-500px{
  width: 500px !important;
}

@include media-breakpoint-down(sm) {
  .offcanvas-w-500px{
    width: 300px !important;
  }
}
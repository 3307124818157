// 
// nav.scss
// Extended from Bootstrap
// 

// 
// Additional style for theme
// 

// Pills
.nav-pills {
  .nav-link {
    cursor: pointer;
    // margin-bottom: 15px;
    padding: 0.5rem 1rem;
    color: var(--#{$prefix}primary);
    white-space: nowrap;
  }
}

// Nav link padding left 0
.nav.flex-column:not(.nav-pills) .nav-link {
  padding-left: 0;
  padding-top: 0.30rem;
  padding-bottom: 0.30rem;
}

// Nav responsive
@include media-breakpoint-down(lg){
  .nav-responsive{
    overflow-x: scroll;
    flex-wrap: nowrap;
  }
} 

// Nav divider
.nav.nav-divider{
  a:not(.dropdown-item){
    color: inherit;
  }
  .nav-item+.nav-item:before{
    content: "\2022";
    color: inherit;
    padding-left: .65rem;
    padding-right: .75rem;
    opacity: 0.8;
  }
  .nav-item{
    display: inline-block;
    vertical-align: middle;
  }
  .nav-link{
    padding: 0;
    color: inherit;
  }
}

// Nav pills primary soft
.nav-pills-primary-soft{
  .nav-item{
    padding: 0 !important;
    margin-right: 2px;
    margin-left: 2px;
    border-bottom: 0 !important;
  }
  .nav-link{
    padding: 0.5rem 0.8rem !important;
    color: var(--#{$prefix}gray-800);
    font-weight: 500;
    border-radius: $border-radius;

    &.active , &:hover{
      color: var(--#{$prefix}primary);
      background-color: rgba(var(--bs-primary-rgb), 0.1);
    }
  }
}
.nav-pills-primary-soft.flex-column{
  .nav-item{
      margin-bottom: 3px;
      margin-right: 0;
    }
}

// Nav pill dark
.nav-pills-dark .nav-link {
  background-color: var(--#{$prefix}gray-200);
  color: var(--#{$prefix}gray-900);
  border: none;
  font-weight: 600;
}
.nav-pills-dark .nav-link.active, .nav-pills-dark .show > .nav-link {
  color: $white;
  background-color: $dark;
}

// Tab line
.nav.nav-bottom-line{ 
  .nav-link{
    background: transparent;
    border: transparent;
    border-bottom: 3px solid transparent;
    font-weight: 600;
    padding: 0.6rem 0.75rem;
    white-space: nowrap;
    &.active {
      background: transparent;
      color: var(--#{$prefix}primary);
      border-bottom: 3px solid var(--#{$prefix}primary);
    }
  }  
}

// Nav pills shadow
.nav-pills-shadow{
  .nav-item{
    padding: 0 !important;
    border-bottom: 0 !important;
  }
  .nav-link{
    padding: 0.5rem 0.8rem !important;
    color: var(--#{$prefix}body-color);
    font-weight: 500;
    border-radius: $border-radius;
    white-space: nowrap;
    background-color: transparent;
    border: 1px solid transparent;
    &:hover{
      color: var(--#{$prefix}gray-800);
    }
    &.active{
      color: var(--#{$prefix}gray-800);
      background-color: var(--#{$prefix}light);
      border: 1px solid $input-border-color;
    }
    &:focus-visible{
      outline: none !important;
    }
  }
}
.nav-pills-primary-soft.flex-column{
  .nav-item{
      margin-bottom: 3px;
      margin-right: 0;
    }
}


// 
// card.scss
// Extended from Bootstrap
//

// 
// Additional styles for theme
// 

// Quick fix for border radius issue in safari
.card.overflow-hidden, .tiny-slider.overflow-hidden{
  will-change: transform;
}

.card-header-title{
  margin-bottom: 0;
}

// Card image overlay z index
.card{
  .card-img-overlay{
    z-index: 2;
  }
}

// Card hover element show
.card.card-element-hover{
  .hover-element{
    visibility: hidden;
    opacity: 0;
    transition: $transition-base;
    z-index: 9;
  }
  &:hover{
    .hover-element{
      visibility: visible;
      opacity: 1;
    }
  }
}

// Card-image scale
.card.card-img-scale {
  .card-img-scale-wrapper{
    transform: translateZ(0);
    position: relative;
    overflow: hidden;
  }
  img {
    transition: all .5s ease;
    transform: scale(1.001);
  }
}
.card.card-img-scale:hover img {
  transform: scale(1.03);
}

// Hover overlay
.card-overlay-hover:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
	transition: $transition-base;
  background-color: $dark;
  display: block;
}
.card-overlay-hover:hover:before {
  opacity: 0.5;
}

// Card hover shadow
.card.card-hover-shadow{
  box-shadow: 0px 3px 20px rgba(29, 58, 83, .08);
  transition: $transition-base;
}
.card.card-hover-shadow:hover{
  transform: translateY(-4px);
  box-shadow: 0px 10px 30px rgba(29, 58, 83, .15);
}
  
// card grid for fix height
.card-grid {
  .card-grid-lg{
    height: 400px;
  }
  .card-grid-sm{
    height: calc(200px - calc(var(--bs-gutter-y) / 2));
  }
}
@include media-breakpoint-down(md) {
  .card-grid .card-grid-lg{
    height: 320px;
  }
}

//Card metro
.card-metro {
  overflow: hidden;

  .card-img-overlay {
    z-index: 2;
  }

  .card-text, img {
    transition: $transition-base;
  }

  &:before {
    content: "";
    position: absolute;
    height: 70%;
    width: 100%;
    bottom: 0;
    background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 1));
    z-index: 1;
  }

  &:hover {
    .card-text {
      margin-bottom: 10px;
    }

    .card-image img {
      transform: scale(1.08);
    }
  }
}

// 
// navbar-mobile.scss
// Theme Component

// Font size for form
.navbar-mobile{
  display: none;
}
@include media-breakpoint-down(sm){
  .navbar-mobile{
    display: block;
    background-color: var(--#{$prefix}mode);
    border-radius: $border-radius-lg $border-radius-lg 0 0 !important;
    width: 100%;
    position: fixed;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    z-index: 99999;
    padding: 0.5rem 2rem;
    box-shadow: 0 1rem 3rem rgba(29, 58, 83, .5);
    .navbar-nav{
      flex-direction: row;
      display: flex;
      justify-content: space-between !important;
    }
    .nav-item{
      text-align: center;
      .nav-link{
        font-size: 1.3rem;
        color:  var(--#{$prefix}body-color);
        padding: 0;
        &.active, &:hover{
          color: var(--#{$prefix}navbar-active-color);
        }
        .nav-text{
          font-size: 0.60em;
          display: block;
        }
      }
      
    }

  }
}

// For footer spacing
@include media-breakpoint-down(sm) {
  .has-navbar-mobile footer{
    padding-bottom: 4rem !important;
  }
} 
// 
// form-guest.scss
// Theme Component

// Font size for form
.form-fs-lg {
  .form-guest-selector{
    font-weight: $font-weight-normal;
    font-size: $form-fs-lg;
    color: $headings-color;
  }
}

.form-fs-md {
  .form-guest-selector{
    font-weight: $font-weight-normal;
    font-size: $form-fs-md;
    color: $headings-color;
  }
}

.form-fs-sm {
  .form-guest-selector{
    font-weight: $font-weight-normal;
    font-size: $form-fs-sm;
    color: $headings-color;
  }
}

// Form border
.form-control-border{
  .form-guest-selector{
    margin-top: -1.2rem;
    padding-top: 1rem !important;
    padding-bottom: 0.8rem !important;
    color: $headings-color;
  }
}

.form-guest-selector[aria-expanded=true]{
  border-radius: $input-border-radius $input-border-radius 0 0 !important;
  border-color: $input-focus-border-color;
}
.form-guest-selector.form-control-lg[aria-expanded=true]{
  border-radius: $input-border-radius-lg $input-border-radius-lg 0 0 !important;
  border-color: $input-focus-border-color;
}
.guest-selector-dropdown {
  // background-color: $dropdown-bg;
  border: $input-border-width solid $input-focus-border-color !important;
  border-radius: 0;
  border-bottom-left-radius: $border-radius !important;
  border-bottom-right-radius: $border-radius !important;
  margin-top: -4px !important;
}

.guest-selector-count {
  min-width: 30px;
  text-align: center;
}



// 
// dropzone.scss
// Vendor style override and new style added

.dropzone {
  min-height: auto;
  background: var(--#{$prefix}body-bg);
  border-radius: $border-radius;
  border: 2px dashed var(--#{$prefix}gray-300);
}
.dropzone .dz-preview.dz-image-preview {
  background: var(--#{$prefix}body-bg);
}

// Dropzone custom
.dropzone-custom { 
  position: relative; 
}
.dropzone-custom .dz-cover-preview {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
}
.dropzone-custom .dz-preview { 
  min-height: auto; 
  margin: 0; 
  width: 100%; 
}
.dropzone-custom.dropzone-cover .dz-message {
  padding: 6rem 2rem;
}
.dropzone-custom .dz-message {
    position: relative;
    padding: 2rem;
    margin: 0;
}
.dropzone-custom .dz-cover-preview .dz-cover-preview-wrap {
    position: absolute;
    height: 100%;
    width: 100%;
    border: 0!important;
    overflow: hidden;
}
.dropzone-custom .dz-preview.dz-cover-preview .dz-cover-preview-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.375rem;
}
.dropzone-custom.dropzone-cover.dz-file-processing .dz-message {
    display: none;
}
.dropzone-custom.dz-clickable .dz-message, .dropzone.dz-clickable .dz-message * {
    cursor: pointer;
}
.dropzone-custom.dropzone-cover.dz-max-files-reached .dz-message {
    background-color: rgba($black, 0.5);
    color: $white;
    opacity: 0;
    transition: all .2s ease-in-out;
    border-color: transparent;
}
.dropzone-custom.dropzone-cover.dz-max-files-reached:focus .dz-message, .dropzone.dropzone-cover.dz-max-files-reached:hover .dz-message {
    opacity: 1;
}
.dropzone-custom.dropzone-cover.dz-file-processing.dz-file-complete .dz-message {
    display: block;
}
.dropzone-custom.dropzone-cover .dz-preview.dz-cover-preview .dz-cover-preview-img {
  width: 100%;
  height: 100%;
}
.dropzone-custom.dropzone .dz-preview {
  display: inline-flex;
}
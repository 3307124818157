//
// accordion.scss
// Extended from Bootstrap
//

//
// Bootstrap Overrides
//
.accordion .accordion-button{
  text-align: left;
}

//
// Additional style for theme
// 

// Accordion collapse plus/minus icon
.accordion{
  .accordion-item {
    color: $accordion-color;
  }
  &.accordion-icon{
    .accordion-header{
      position: relative;
      .accordion-button{
        background: transparent;
        font-size: inherit;
        border: none;
        padding-right: 2rem;
        &:after {
          content:"";
          background: var(--#{$prefix}gray-800) !important;
          width: 10px;
          height: 2px;
          display: block;
          position: absolute;
          top: 47%;
          right: 20px;
          transform: translateY(-50%);
          transition: $transition-base;
          transform: rotateZ(0deg);
          z-index: 9;
        }
        &:before {
          content:"";
          background: var(--#{$prefix}gray-800) !important;
          width: 10px;
          height: 2px;
          display: block;
          position: absolute;
          top: 47%;
          right: 20px;
          transform: translateY(-50%);
          transition: $transition-base;
          transform: rotateZ(0deg);
          z-index: 9;
        }
        &[aria-expanded=false]:after {
          transform: rotateZ(90deg);
        }
        &[aria-expanded=true]:before {
          transform: rotateZ(0deg);
        }
      }
    }
    .accordion-collapse{
      border: none;
    }
    .accordion-body {
      padding: 0.2rem 1rem 0.5rem 1rem;
    }
  }
}

// Accordion icon bg light
.accordion.accordion-bg-light {
  .accordion-item {
    border: none;
    background-color: transparent;
  }
  .accordion-button {
    background-color: var(--#{$prefix}light)!important;
    color: var(--#{$prefix}gray-800) !important;
    &:not(.collapsed) {
      box-shadow: none !important;
    }
  }
}

@include media-breakpoint-down(sm) {
  .accordion-icon .accordion-body {
    padding: 0.6rem 0.5rem 0.5rem 0.5rem !important;
  }
  .accordion-header .accordion-button {
    padding-left: 1.3rem !important;
  }
}
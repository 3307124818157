// 
// quill.scss
// Vendor style override and new style added

.ql-snow {
  .ql-picker{
    color: var(--#{$prefix}gray-700);
  }
  .ql-fill, .ql-stroke.ql-fill{
    fill: var(--#{$prefix}gray-700);
  }
  .ql-stroke{
    stroke: var(--#{$prefix}gray-700);
  }
}